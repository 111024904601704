* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: #333;
  font-family: 'GT-Walsheim';
  font-size: 14px;
  line-height: 1.3;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 26px;
  letter-spacing: -0.52px;
}

h2 {
  font-family: 'GT-Walsheim';
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 18px;
  margin-bottom: 0.5rem;
}

h3 {
  font-family: 'GT-Walsheim';
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 16px;
  text-align: center;
}

p {
  color: #958784;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  80% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-10%);
  }
}
